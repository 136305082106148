<template lang="pug">
.cookies-notification(v-bind:class="{'in': showDisclaimer}" v-if="cookiesEnabled")
    i18n(path="general.cookies-disclaimer" tag="p")
        a(:href="cookiePolicyUrl" target="_blank" rel="noopener noreferrer") {{ $t('general.cookies-disclaimer-link') }}
    p
        button.btn.btn-default.btn-primary(@click.prevent="reject") {{$t(rejectLabel)}}
        button.btn.btn-default.btn-primary(@click.prevent="accept") {{$t(agreeLabel)}}

</template>
<script>
export default {
    name: 'Cookies',

    data() {
        return {
            key: 'cookies-policy',
            cookiePolicyUrl: process.env.cookiePolicyUrl,
            agreeLabel: 'general.agree',
            rejectLabel: 'general.reject',
            showDisclaimer: false,
            cookiesEnabled: true
        };
    },

    created() {
        const accepted = this.$cookies.get(this.key);
        this.$brand.get().then(({ body }) => {
            if (!accepted && body.show_cookie_banner) {
                setTimeout(() => {
                    this.showDisclaimer = true;
                }, 1000);
            } else {
                this.$track.init(this);
                this.clearElement();
            }
        });
    },

    methods: {
        clearElement() {
            this.showDisclaimer = false;
            setTimeout(() => {
                this.cookiesEnabled = false;
            }, 1000);
        },

        reject() {
            this.$cookies.set(this.key, 0);

            const cookies = this.$cookies.keys();
            const beacons = cookies.filter(c => c.startsWith('mp_'));

            beacons.forEach(b => {
                // Remove cookie from main domain
                const host = location.host.replace(/(:\d{1,5})?$/, '');

                this.$cookies.remove(b, '/', `.${host}`);

                // Remove cookie from TLD
                let tld = host.split('.');

                tld.shift();
                tld = tld.join('.');
                this.$cookies.remove(b, '/', `.${tld}`);
            });

            this.clearElement();
        },

        accept() {
            this.$cookies.set(this.key, 1);
            this.$track.init(this);
            this.clearElement();
        }
    }
};
</script>
