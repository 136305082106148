<template lang="pug">
.session-details-modal.modal-wrapper.large
    .modal-header
        a.close(@click="$emit('close')")
    .modal-body
        session-details(
            :config="config"
            :session="session"
            :stop-propagation="true"
            :open-details-on-click="false"
            :condensed="true"
            :disabled="false"
        )
        .desc(v-html="description")

    .modal-footer
</template>

<script>

export default {
    name: 'SessioonDetailsModal',

    props: {
        session: {
            type: Object,
            required: true
        },
        config: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            title: this.session.title,
        }
    },

    computed: {
        description() {
            const { locale } = this.$i18n;
            return this.session.description_i18n?.[locale] || this.session.description;
        }
    },
};
</script>
