<template lang="pug">
form.legals(@submit.prevent="activate")
    .form-check(v-for="(legal, index) in requirements")
        legal-requirement(:requirement="legal" @selection="legalChange")
            login-action(:action="legal" target="_blank")
    .form-actions.text-right
        button.btn(:disabled="!submitEnabled") {{ $t('login.submit') }}

</template>
<script>
export default {
    name: 'Legals',

    props: [ 'invite', 'requirements', 'redirect' ],

    data() {
        return {
            submitEnabled: false,
            allRequirementsAccepted: false
        };
    },

    methods: {
        validate() {
            this.allRequirementsAccepted = this.requirements.filter(l => l.label).every(
                legal => legal.accepted
            );
            this.submitEnabled = this.allRequirementsAccepted;
            return this.submitEnabled;
        },

        legalChange(id, checked) {
            this.requirements.forEach(legal => {
                if (legal.id === id) {
                    legal.accepted = checked;
                }
            });

            this.validate();
        },

        async activate() {
            try {
                const url = await this.$services.event.getInvitationUrl(this.$route.params.branding, this.invite);
                if (url) {
                    this.$services.routing.redirect(url, {redirect: this.redirect});
                    return;
                }

                throw new Error('login.error-online-not-available');

            } catch (error) {
                this.$root.$emit('activation:error', error.message);
            }
        }
    }
};
</script>
