// Utils
import { get, merge, set, cloneDeep } from 'lodash';
import { default as languageList } from 'langmap';


/**
 * Transforms a JSON object into a flat "dot-noted" string key object.
 * I.E.:
 *
 * "account": {
 *      "title": {
 *          "en": "Log in"
 *      }
 *  }
 *
 * will become:
 *
 * `{"account.title.en": "Log in"}`
 *
 * @param {object} messages the i18n message table
 * @param {object} [res={}]
 * @param {string} [prevKey=null]
 *
 * @returns {object} a new object containing all flattened keys with respective values.
 *
 * @private
 */
function flattenObject(messages, res = {}, prevKey = null) {
    Object.keys(messages).forEach(key => {
        const k = prevKey ? prevKey + '.' + key : key;

        if (typeof messages[key] === 'object') {
            return flattenObject(messages[key], res, k);
        }

        if (typeof messages[key] === 'string') {
            res[k] = messages[key];
        }
    });

    return res;
}

/**
 * We use this method in order to have a vue's compatible i18n table.
 * It transforms keys such as.
 *
 * "account": {
 *      "title": {
 *          "en": "Log in"
 *      }
 *  }
 *
 * into something like this:
 * "en": {
 *     "account" {
 *         "title": "Log in"
 *     }
 * }
 *
 * which is the `vue-i18n` standard form.
 *
 * NOTE: if server responded with a key we already have declared on the
 * i18n.json file, server key takes priority over previous key.
 *
 * @param {object} localeMessages the original messages map
 * @param {object} messages the i18n message table
 *
 * @returns {object} the remapped and merged locales object
 */
export function remapMessages(localeMessages, messages) {
    const flatMessages = flattenObject(messages);
    const mergedMessages = cloneDeep(localeMessages);

    Object.keys(flatMessages).forEach(key => {
        const keyParts = key.split('.');
        const lang = keyParts.pop();
        const loc = {};

        set(loc, keyParts.join('.'), get(messages, key));
        mergedMessages[lang] = merge(mergedMessages[lang] || {}, loc);
    });

    return mergedMessages;
}

/**
 * Selects the locale to translate the pages into.
 * First it tries to see if there is a preferred language. If so, it uses this one.
 * If no locale is set as preferred, it checks if some is set under `locale_selected` in local storage.
 * Otherwise, gets the first overlap language between browser and the given locales array.
 * If none is found falls back to 'en'.
 *
 * @param {object} args
 * @param {string[]} [args.locales = []] an array of available localized messages
 * @param {string[]} [args.languages = navigator.languages] an array of client supported languages
 * @param {Storage} [args.storage = localStorage] the storage containing the preferred user language
 * @param {string} [args.preferredLocale] the preferred locale
 *
 * @returns {string} the first common locale or 'en'
 */
export function getFirstEligibleLocale({
    locales = [],
    languages = Array.from(navigator.languages),
    storage = localStorage,
    preferredLocale
} = {}) {
    let extendedLanguages = [];

    if (preferredLocale) {
        extendedLanguages.push(preferredLocale);
    }

    const previouslySavedLanguage = storage.getItem('locale_selected');
    if (previouslySavedLanguage) {
        extendedLanguages.push(previouslySavedLanguage);
    }

    if (typeof Intl === 'object' && typeof Intl.Locale === 'function') {
        languages.forEach(l => {
            const locale = new Intl.Locale(l);
            extendedLanguages.push(locale.baseName);
            if (!extendedLanguages.includes(locale.language)) {
                extendedLanguages.push(locale.language);
            }
        });
    } else {
        extendedLanguages.push(...languages);
    }

    const commonLocales = extendedLanguages.filter(value => locales.includes(value));
    let locale;
    if (commonLocales.length) {
        locale = commonLocales[0];
        console.info('[Locales] Common locale detected:', locale);
    } else {
        locale = get(locales, '0', 'en');
        console.info('[Locales] No common locale detected, using main language:', locale);
    }

    return locale;
}

/**
 * @param {string} langCode
 * @returns {string}
 */
export function getLanguageNativeName(langCode) {
    const lang = languageList[langCode];

    if (!lang) {
        console.error(`[Locales] Unrecognized language code: '${langCode}'`);
        return langCode;
    }

    return lang.nativeName;
}


/**
 * Retrieves the legal messages for the given requirements.
 *
 * @param {Array} requirements - The array of requirements.
 * @param {boolean} required - Indicates if the requirements are required.
 *
 * @returns {Object} - The object containing the legal messages for the requirements.
 */
export function getLegalMessages(requirements, required) {
    const requirementsLabels = {};

    for (const requirement of requirements) {
        for (const [ locale, value ] of Object.entries(requirement.i18n)) {
            let { id, label, ...labels } = value;

            if (!label) {
                console.warn('[Locales] Missing label for requirement:', id);
                label = requirement.label || '';
            }

            label = label.replace('{{link}}', '{0}');

            if (required) {
                label += ' *';
            }

            set(requirementsLabels, `${locale}.requirements.${id}`, label);
            set(requirementsLabels, `${locale}.requirement_labels.${id}`, labels);
        }
    }

    return requirementsLabels;
}


/**
 * Retrieves the legacy legal messages for the given requirements.
 *
 * @param {Array} requirements - The list of requirements.
 * @param {boolean} required - Indicates if the requirements are required.
 * @param {string} currentLocale - The current locale.
 * @param {Object} allMessages - The object containing all the messages.
 *
 * @returns {Object} - The requirements labels object.
 *
 * @deprecated Use getLegalMessages instead.
 */
export function getLegacyLegalMessages(requirements, required, currentLocale, allMessages) {
    console.warn('[Locales] Using legacy legal messages for requirements');
    const requirementsLabels = {};

    for (const requirement of requirements) {
        let label = requirement.label.replace('{{link}}', '{0}');
        const key = `registration.requirements.${requirement.id}`;
        const tLabel = get(allMessages, key) || get(allMessages, `'en'.${key}`);

        if (tLabel) {
            label = tLabel;
        }

        if (required) {
            label += ' *';
        }

        set(requirementsLabels, `${currentLocale}.requirements.${requirement.id}`, label);
    }

    return requirementsLabels;
}
