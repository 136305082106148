/**
 * @typedef {object} ViewError
 * @property {string} text the server response status text
 * @property {number} status the server response status code
 * @property {string} message the translation key of the error message
 * @property {object} [response]
 */

/**
 * This method is used to intercept all requests that result
 * with a status code outside the range of 2xx.
 *
 * @param {import('axios').AxiosError} error the request error
 *
 * @returns {Promise<object>}
 */
export function requestErrorInterceptor(error) {
    const err = buildErrorForView(error.response || { data: {} });
    return Promise.reject(err ? new Error(err.message) : error);
}

/**
 * Given a response object this method creates an
 * error object understandable by the UI
 *
 * @param {object} response the server response
 *
 * @returns {ViewError|undefined} the error to be displayed on the view
 */
export function buildErrorForView(response) {
    const { status, statusText: text, data: { error_code } } = response;

    if (status >= 500 || status === 401) {
        let message = 'login.error-internal-error';

        if (status === 401) {
            message = 'login.error-expired-token';
        }

        if (error_code) {
            message = `login.errors.${error_code}`;
        }

        return { text, status, message, response };
    }
}
