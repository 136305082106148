// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';

/**
 * Registration page config endpoint
 * @constant {string} APP_LINK_API_ENDPOINT
 */
const APP_LINK_API_ENDPOINT = `${API_BASE_PATH}/request-webapp-link`;

/**
 * Registration page config endpoint
 * @constant {string} CONTENT_HUB_LINK_API_ENDPOINT
 */
const CONTENT_HUB_LINK_API_ENDPOINT = `${API_BASE_PATH}/request-content-hub-link`;

/**
 * Legal requirements for the given doc ID
 * @constant {string} REQUIREMENST_API_ENDPOINT
 */
const REQUIREMENST_API_ENDPOINT = `${API_BASE_PATH}/legal/documents/{{docId}}/requirements`;

/**
 * Provides methods necessary for managing events
 *
 * @example
 *
 * import EventService from 'libs/services/event';
 * ...
 * const brand = new EventService();
 */
export default class EventService extends BaseService {

    /**
     * Given an invitation this method calculates its short code
     * starting from the activation code.
     *
     * @param {Invitation} invitation the event's invitation
     *
     * @returns {string|undefined} the short code, undefined if actcode is not defined
     */
    getShortCode({ actcode }) {
        if (!actcode) return;
        return actcode.includes('-') ? actcode.split('-')[0] : actcode.substring(0, 3);
    }

    /**
     * Given an invite, this method will return the correct
     * activation URL.
     *
     * @param {string} branding the branding of the event's app
     * @param  {Invitation|ContentHubInvitation} invitation the invite object
     *
     * @returns {Promise<string|undefined>} the correct URL for the given invite
     */
    async getInvitationUrl(branding, invitation) {
        const isContentHub = !invitation.hasOwnProperty('eid');

        const getLink = () => isContentHub
            // @ts-ignore
            ? this.getContentHubLink(branding, invitation)
            // @ts-ignore
            : this.getAppLink(branding, invitation);

        const { data } = await getLink();
        if (data.url?.length) {
            return data.url;
        }
    }

    /**
     * Given a branding and an invitation, this method
     * requests the web app activation url.
     *
     * @param {string} branding the branding of the event's app
     * @param {Invitation} invitation the invite object
     *
     * @return {Promise} a promise which returns an HTTP response
     *
     * @private
     */
    getAppLink(branding, { eid, pid, token }) {
        return this.post(APP_LINK_API_ENDPOINT, {
            branding, eid, pid,
            invitationToken: token
        });
    }

    /**
     * Given a branding and an invitation, this method
     * requests the content hub activation url.
     *
     * @param {string} branding the branding of the event's app
     * @param {ContentHubInvitation} invitation the invite object
     *
     * @return {Promise} a promise which returns an HTTP response
     *
     * @private
     */
    getContentHubLink(branding, { id }) {
        return this.post(CONTENT_HUB_LINK_API_ENDPOINT, {
            branding, contentHubId: id
        });
    }

    /**
     * Given a legal doc ID this method returns the legal requirements
     * associated to the doc.
     *
     * @param {string} docId the ID of the legal document
     *
     * @returns {Promise<object[]>} the legal requirements
     */
    async getRequirementsDocById(docId) {
        const url = this.buildUrl(REQUIREMENST_API_ENDPOINT, { docId });
        const { data } = await this.get(url);
        return data;
    }
}
