// Utils
import { get } from 'lodash';

// Components
import ExpandableList from '@/components/common/ExpandableList.vue';

export default {
    components: { ExpandableList },

    props: {
        config: {
            type: Object,
            required: true
        },

        section: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            visible: false
        };
    },

    computed: {
        description() {
            const { locale, fallbackLocale } = this.$i18n;
            return get(this.section, `content.${locale}`, get(this.section, `content.${fallbackLocale}`));
        },
        title() {
            const { locale, fallbackLocale } = this.$i18n;
            return get(this.section, `title.${locale}`, get(this.section, `title.${fallbackLocale}`));
        }
    },

    created() {
        this.visible = Boolean(this.title || this.description);
    }
};
