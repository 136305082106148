import { find } from 'lodash';

const BLACK_LISTED_WORDS = [ 'token', 'email', 'password' ];

const Track = {
    LOCAL_STORAGE_KEY: 'spotme.webapp.login.track',

    init(vue) {
        console.debug('[Track] init...');
        this.vue = vue.$root;
        this.initialized = false;
        this.hasAcceptedCookies =
            vue && vue.$cookies.get('cookies-policy') === '1';
        this.initTracker();
    },

    initTracker() {
        if (!this.hasAcceptedCookies) {
            return;
        }
        this.initialized = true;
    },

    /**
     * Performs a check if the current url can be tracked or not based on a dictionary of black listed tokens.
     *
     * @param {String} [location=window.location.href] the path to track.
     * @returns {Boolean} true if the current url is safe, false otherwise
     */
    canTrack(location = window.location.href) {
        if (!this.initialized) {
            return false;
        }

        const foundTokens = find(
            BLACK_LISTED_WORDS,
            w => location.toLocaleLowerCase().indexOf(w) !== -1
        );
        const canTrack = !foundTokens;

        if (!canTrack) {
            console.warn('[TrackingService] Protected word detected.');
        }

        return canTrack;
    },

    track(event, properties = {}) {
        if (!this.initialized) {
            this.initTracker();
        }

        return new Promise(resolve => {
            if (this.canTrack()) {
                properties.branding = this.vue.$route.params.branding;
                properties.env = this.vue.$const.APP_ID;

                // only a log for now, we'll see later if we need to track more
                // or fully remove this service
                console.log('[TrackingService] Track:', event, properties);
            }

            resolve();
        });
    },

    startTrackLogin(type) {
        try {
            console.log('[TrackingService] Start tracking:', type);
            localStorage.setItem(
                this.LOCAL_STORAGE_KEY,
                JSON.stringify({
                    type
                })
            );
        } catch (x_X) {
            // could not use local storage to track a successfull activation:
            // do nothing. Report these login as 'unknown'
            this.track('login_evt', {
                type,
                state: 'unknown'
            });
        }
    },

    endTrackLogin(success) {
        let existing = null;
        // fail safe retrieval of existing tracking in local storage.
        try {
            existing = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
        } catch (x_X) {
            // do nothing
        }

        if (existing?.type) {
            this.track('login_evt', {
                type: existing.type,
                state: success ? 'ok' : 'ko'
            });
        }

        if (success) {
            // erase anything stored, in a fail-safe way
            try {
                localStorage.removeItem(this.LOCAL_STORAGE_KEY);
            } catch (x_X) {
                // do nothing
            }
        }
    }
};

export default {
    install(Vue) {
        Vue.prototype.$track = Track;
    }
};
