import 'core-js/stable';
import { get, isString } from 'lodash';

/**
 * Build a query string from a map of values.
 * Does not support array of values
 * @param {Object} obj map of key/values
 * @returns {String}
 */
export function toQueryString(obj) {
    return new URLSearchParams(obj).toString();
}

/**
 * Build a map of values from a query string
 * Does not support array of values
 * @param {String} str the query string
 * @returns {Object} map of key/values
 */
export function fromQueryString(str) {
    const parsed = new URLSearchParams(str);
    const map = {};
    for (const [ key, value ] of parsed) {
        map[key] = value;
    }
    return map;
}

/**
 * strips out the query string from the url
 * @param {String} url
 * @return {String}
 */
export function removeQueryString(url) {
    if (!url || !isString(url)) {
        return url;
    }
    return url.split('?')[0] || '';
}

export function convertEmbedableUrl(url) {
    const table = {
        youtube: {
            test: url => /^https:\/\/((www|m)\.)?youtu(\.be|be\.com)\/watch(\?|\/)/.test(url),
            id: url => get(url.match(/(\?|&)v=[a-zA-Z0-9]+/), '0', '').replace(/(\?|&)v=/, ''),
            convert: (url, id) => url.replace(/\/watch(\?|\/).*$/, `/embed/${id}`)
        },
        vimeo: {
            test: url => /^https:\/\/((www|player)\.)?vimeo\.com\/[0-9]+/.test(url),
            id: url => get(url.match(/[0-9]+$/), '0', ''),
            convert: (_, id) => `https://player.vimeo.com/video/${id}`
        }
    };

    let converted = url;
    for (const media of Object.keys(table)) {
        if (table[media].test(url)) {
            converted = table[media].convert(url, table[media].id(url));
            break;
        }
    }

    return converted;
}

/**
 * Checks whether the given url can be read by our video player
 * @param {String} url
 * @return {Boolean}
 */
export function isPlayableVideoUrl(url = '') {
    let fullUrl = url.toString().startsWith('http') ? url : `https://spotme.com${url}`;
    const path = new URL(fullUrl).pathname;

    return path.endsWith('m3u8') || path.endsWith('mpd') || path.endsWith('mp4');
}

/**
 * Adds query params to a url
 * - whether there are query already query params or not
 * - whether "url" is an actual url or not
 * - if url is not a string it is returned as is
 * @type {<T>(url: T, query?: Record<string,unknown>) => T}
 */
export function addQueryParamsToUrl(url, query = {}) {
    if (!isString(url)) { return url; }
    const [ urlWithoutQuery, queryString ] = url.includes('?') ? url.split('?') : [ url, '' ];
    const params = new URLSearchParams(queryString);
    Object.entries(query).forEach(([ key, value ]) => params.append(key, value));
    return `${urlWithoutQuery}?${params.toString()}`;
}

/**
 *
 * @param {String} newUrl
 * @param {String} oldUrl
 * @return {Boolean}
 */
export function isSameUrlWithoutQuery(newUrl, oldUrl) {
    if (newUrl === oldUrl) {
        return true;
    }

    const newUrlWithoutQuery = removeQueryString(newUrl);
    const oldUrlWithoutQuery = removeQueryString(oldUrl);

    return newUrlWithoutQuery === oldUrlWithoutQuery;
}

/**
 * @param {string} originalUrl
 * @param {string} [whiteLabelDomain]
 * @returns {string}
 */
export function replaceHostIfHasWhiteLabelDomain(originalUrl, whiteLabelDomain) {
    if (!whiteLabelDomain) {
        return originalUrl;
    }

    return originalUrl.replace(new URL(originalUrl).host, whiteLabelDomain);
}
