import moment from 'moment-timezone';
const formatTimestamp = (ts, timezone) => {
    const date = moment.unix(ts);
    if (timezone) {
        return date.tz(timezone).format('YYYYMMDDTHHmmss');
    } else {
        return date.utc().format('YYYYMMDDTHHmmss') + 'Z';
    }
};

/**
 * Given a event configuration this method returns a
 * valid google calendar URL
 *
 * @param {object} config the event configuration
 * @param {string} config._id the unique ID for this event
 * @param {string} config.name the name for the event
 * @param {string} config.start the starting time of the event
 * @param {string} config.end the ending time of the event
 * @param {string} config.content the message to append to the calendar
 * @param {string} [config.location] the location of the event
 *
 * @param {string} [timezone] the desired timezone
 * @returns {string} a formatted URL for adding to google calendar
 */
export function googleCalendarUrl(config, timezone) {
    const { name, start, end, location, content, _id } = config;

    // timezone translate, google does not like some timezones
    const timezoneToRemap = {
        'US/Eastern': 'America/New_York',
        'US/Central': 'America/Chicago',
        'US/Mountain': 'America/Phoenix',
        'US/Pacific': 'America/Los_Angeles',
        'EST5EDT': 'America/New_York',
        'EST': 'America/New_York',
        'EDT': 'America/New_York'
    };

    timezone = timezoneToRemap[timezone] || timezone;

    return (
        'https://www.google.com/calendar/render?action=TEMPLATE' +
        '&pli=1&sf=true&output=xml' +
        `&text=${encodeURIComponent(name)}` +
        `&dates=${formatTimestamp(start, timezone)}/${formatTimestamp(
            end ? end : start,
            timezone
        )}` +
        `&ctz=${encodeURIComponent(timezone)}` +
        (location ? `&location=${encodeURIComponent(location)}` : '') +
        `&details=${encodeURIComponent(content)}` +
        `&uid=${_id}` +
        '#eventpage_6'
    );
}
