/**
 * Use this util to manipulate or validate strings.
 */
const Strings = {
    validateEmail(email) {
        return this.EMAIL_REG_EXP.test(String(email).toLowerCase());
    },

    EMAIL_REG_EXP: /^.+@.+$/i
};

export default {
    install(Vue) {
        Vue.prototype.$strings = Strings;
    }
};
