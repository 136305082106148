// Plugins
import PortalVue from 'portal-vue';
import VModal from 'vue-js-modal';
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta';
import VueResource from 'vue-resource';
import VueScrollactive from 'vue-scrollactive';

// Utils
import { buildErrorForView } from '@/libs/utils/interceptors';
import axios from 'axios';

export function registerPlugins(Vue) {
    Vue.use(PortalVue);
    Vue.use(VModal, { dynamic: true, dialog: true });
    Vue.use(VueCookies);
    Vue.use(VueMeta);
    Vue.use(VueResource);
    Vue.use(VueScrollactive);

    function emitLoadingEvent(eventName) {
        const event = new CustomEvent(eventName);
        window.dispatchEvent(event);
    }

    // [VueResource] Set up global request interceptors.
    Vue.http.interceptors.push(function(request, next) {
        // show loading popup when a http call starts
        emitLoadingEvent('loading:start');

        return next(rsp => {
            emitLoadingEvent('loading:stop');
            const err = buildErrorForView(rsp);

            if (err) {
                this.$root.errors = [ err ];
                return Promise.reject(new Error(err.message));
            }
        });
    });

    axios.interceptors.request.use(
        (config) => {
            emitLoadingEvent('loading:start');
            return config;
        },
        (/** @type {Error} */ error) => {
            emitLoadingEvent('loading:stop');
            return Promise.reject(error);
        });

    axios.interceptors.response.use(
        (response) => {
            emitLoadingEvent('loading:stop');
            return response;
        },
        (/** @type {Error} */ error) => {
            emitLoadingEvent('loading:stop');
            return Promise.reject(error);
        });
}
